import React from 'react'

const Contact = () => {
  return (
    <div className='container-contact'>
          <div className='bg-feature-contact'>
                <h1 className='hero-contact'>Lets Talk</h1>
                <p>Technical Support 24/7.</p>
          </div>

          <div className='feature-contact'>
            <div className='box-contact'>
                <div className='lets-talk'>
                    <h1>Lets Talk</h1>
                    <p>arjunadedi04@gmail.com</p>
                </div>
                <div className='view-talk'>
                <iframe
                  src="https://www.youtube.com/embed/E7wJTI-1dvQ"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  title="video"
                />
                </div>
            </div>
          </div>
          <br></br>
          <br></br>
    </div>
  )
}

export default Contact
