import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ScrollToTop from "./_helper/scrolltotop";

import Beranda from './Flack/Halaman/Beranda/Beranda'
import About from './Flack/Halaman/About/About'
import Jurnal from './Flack/Halaman/Jurnal/Jurnal'
import Contact from './Flack/Halaman/Contact/Contact'

import Footer from "./Flack/Footer/Footer";

// Import CSS Style
import './Style/main.css'
import './Style/main-mobile.css'
import './Flack/Footer/footer.css'
import './Flack/Directive/directive.css'
import Directive from "./Flack/Directive/Directive";

function App() {
  return (
    
    <Router>
      <Directive />
      <ScrollToTop />
          <Switch>
              <Route path='/' component={Beranda} exact> <Beranda /></Route>
              <Route path='/about' comonent={About} exact> <About /></Route>
              <Route path='/jurnal'  component={Jurnal} exact> <Jurnal /></Route>
              <Route path='/contact' component={Contact} exact> <Contact /></Route>
          </Switch>
          <Footer />
    </Router>
  )
}
export default App;
