import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import '../../Style/main-mobile.css'

const Directive = () => {

  const [sticky, setSticky] = useState (false);

  useEffect(() => {
    const handleScroll = () => {
        setSticky(window.scrollY >200)
    }
    window.addEventListener ('scroll', handleScroll)
    return () => window.removeEventListener ('scroll', handleScroll)

  })

  return (
    <div className={'sticky ? "sticky" : " "'}>
        <div className='container-nav'>
        <h1 className='logo'>Club'</h1>
            <nav className='navbar'>
                <Link  to='/'><li>Beranda</li></Link>
                <Link  to='/about'><li>About</li></Link>
                <Link  to='/jurnal'><li>Jurnal</li></Link>
                <Link  to='/contact'><li>Contact</li></Link>
            </nav>
        </div>
    </div>
  )
}

export default Directive
