import React from 'react'
import cnnindonesia from '../../../Asset/Image/work-space/cnnindonesia.png'
// import detik from '../../../Asset/Image/work-space/detik.png'
// import Introducing from '../../../Asset/Video/Introducing.mp4'
// import kumparan from '../../../Asset/Image/work-space/kumparan.png'

const Beranda = () => {

  const buttonClick = () =>  {
    alert( "hallo" );
  }

  return (
    <div className='container-beranda'>
{/* Hero Beranda */}
{/* <video className='videoTag'src={Introducing} autoPlay loop muted /> */}

      <div className='hero-beranda'>
        <h1>A global design studio, applying a design that knows no limits.</h1>
        <br></br>
        <button onClick={() => buttonClick ()} > Mulai Konsultasi Sekarang</button>
      </div>

{/* Feature Beranda  */}
      <div className='bg-feature-beranda'>
            <div className='feature-beranda'>
                <h2>Feature </h2>
                <h2>Work</h2>
              <div className='work-space'>
                    <img className='image-beranda' src={cnnindonesia}></img>
                    <img className='image-beranda' src={cnnindonesia}></img>
                    {/* <img className='image-beranda' src={cnnindonesia}></img>
                    <img className='image-beranda' src={cnnindonesia}></img>
                    <img className='image-beranda' src={cnnindonesia}></img>
                    <img className='image-beranda' src={cnnindonesia}></img> */}
              </div>  
            </div>
      </div>
{/* <button onClick={() => buttonClick ()} > Mengapa Begitu</button>
    
     */}
    
    </div>
  )
}

export default Beranda
