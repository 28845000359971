import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FaGithub } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FiArrowUpRight } from "react-icons/fi";

const Footer = () => {
  return (
    <div className='container-footer'>
        <div className='bg-container'>
            <div className='logo-footer'>
                <div className='logo-sidebar'>
                    <h1>The Crapty</h1>
                    <p>Want to work with us?</p>
                </div>

                <div className='sidebar-footer'>
                    <nav className='onefooter'>
                        <Link to='/work'> <li>Work</li></Link>
                    </nav>

                    <nav className='twofooter'>
                        <Link to='/about'> <li>About</li></Link>
                        <Link to='/contact'> <li>Contact</li></Link>
                    </nav>

                    <nav className='threefooter'>
                        <Link to='/jurnal'> <li>Jurnal</li></Link>
                        <Link to='/'> <li>Tentang Kami</li></Link>
                    </nav> 
                </div>
            </div>
        </div>

        <div className='buttom-footer'>
            <div className='buttom-footer-sidebar'>
                        <h1>#Copyright -<a href='/'>Thecraptyclub'<FiArrowUpRight className='icons'/> </a></h1>
                    {/* <hr></hr> */}
                <div className='subIcon'>
                    <a href='https://instagram.com/dediarjuna_'><FaInstagram  /></a>
                    <a href='/'><FaLinkedin  /></a>
                    <a href='https://github.com/dediarjuna/'><FaGithub  /></a>
                </div>
                    {/* <hr></hr> */}
                <div className='subPrivacy'>
                    <p><a href='/gellery'>Gallery</a></p>
                    <p><a href='/client'>Client</a></p>
                    <p><a href='/privacy-policy'>Privacy Policy</a></p>
                    <p><a href='/term'>Term & Condition</a></p>
                    <p><a href='/faq'>FAQ</a></p>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default Footer
