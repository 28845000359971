import React from 'react'
import { FcAbout } from "react-icons/fc";

const Jurnal = () => {
  return (
    <div className='container-jurnal'>
      <div className='box-jurnal'>
        <div className='content-jurnal'>
          <div className='article-jurnal'>
          
            <h1><FcAbout /> 1. Halaman Jurnal</h1>
            <p>C# is one of the most popular.</p>                  
          </div>

          <div className='article-jurnal'>
            <h1><FcAbout /> 2. Halaman Jurnal</h1>
            <p>C# is one of the most popular.</p>                  
          </div>

          <div className='article-jurnal'>
            <h1><FcAbout /> 3. Halaman Jurnal</h1>
            <p>C# is one of the most popular.</p>                  
          </div>

          <div className='article-jurnal'>
            <h1><FcAbout /> 4. Halaman Jurnal</h1>
            <p>C# is one of the most popular.</p>                  
          </div>

          <div className='article-jurnal'>
            <h1><FcAbout /> 4. Halaman Jurnal</h1>
            <p>C# is one of the most popular.</p>                  
          </div>

          
          
        </div>
      </div>
    </div>
  )
}

export default Jurnal
